import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
// Redux
import { connect } from 'react-redux';
// Multi Langauage Support
import { I1BnProvider } from '../../i18n';
import translate from '../../i18n/translate.js';
//images
import Mead3Friends from '../../images/three_mead_friends.jpg'
import MeadPreBottle from '../../images/mead_pre_bottle.jpg'
import CherryMead from '../../images/finished_cherry_product.jpg'
import ChocMead from '../../images/chocolate_mead.jpg'
import VanMead from '../../images/wanilia.jpg'
import CoffeeMead from '../../images/coffee_mead.jpg'

// TODO: Add a glowing "read on" at end of opening container

class Cherry extends Component {
  render() {

    const tempImg = {
      float: 'left',
      height: '20%',
      width: '40%',
    //  maxHeight:'288px'
    }

    const tempImg2 = {
      height: '25vw',
      width: '25vw'
    }

    return (
    <I1BnProvider locale={this.props.active_lang.locale_val}>
      <div>
        <Helmet>
           <title> Mead Making | Recipes and Craftsmanship Blog | Alexander Singleton </title>
           <link rel="canonical" href="https://www.alexanderjohnsingleton.com/mead/recepies/cherry" />
        </Helmet>
        <div className="leftAlignedPage" style={{paddingTop:"0%"}}>
        <h2 style={{marginTop: '15px', textAlign: 'center', paddingTop:"4%", paddingBottom:"2%"}}> { translate('cherry_mead_title') }  </h2>
        <div style={{minHeight:"540px", maxWidth: "1200px"}}>
          <img src={Mead3Friends} alt="cherry mead finished product" style={tempImg}/>
          <div style={{marginLeft:"43%",  paddingTop:"2%"}}>
            <p> { translate('cherry_recipe_p1') } </p>
            <p> { translate('cherry_recipe_p2') } </p>
            <p> { translate('cherry_recipe_p3') } </p>
            <p> { translate('cherry_recipe_p4') } </p>
          </div>
        </div>
        <div>
        <h2> { translate('ingredients') }  </h2>
        <ul>
          <li> { translate('cherry_ing_honey') } </li>
          <li> { translate('cherry_ing_frozen_cherry') } </li>
          <li>  { translate('cherry_ing_fresh_cherry') }  </li>
          <li>  { translate('cherry_ing_pits') }  </li>
          <li> D47 Lalvin Yeast </li>
          <li> Yeast Energizer </li>
          <li> Yeast Nutrient </li>
        </ul>
        <h4> { translate('cherry_ing_opt_flavors') } </h4>
        <ul>
          <li> { translate('cherry_ing_vanillia') } </li>
          <li> { translate('cherry_ing_choc') } </li>
          <li> { translate('cherry_ing_coffee') } </li>
          <li> { translate('cherry_ing_extra_honey') } </li>
          <li> { translate('extra_cherries') } </li>
        </ul>
        </div>
        <h2> { translate('preparation') } </h2>
        <p> { translate('cherry_recipe_p5') } </p>
        <p> { translate('cherry_recipe_p6') } </p>
        <p> { translate('cherry_recipe_p7') } </p>
        <p> { translate('cherry_recipe_p8') } </p>
        <p> { translate('cherry_recipe_p9') } </p>
        <h2> { translate('primary_fermentation') } </h2>
        <p> { translate('cherry_recipe_p10') } </p>
        <p> { translate('cherry_recipe_p11') } </p>
        <p> { translate('cherry_recipe_p12') } </p>
        <p> { translate('cherry_recipe_p13') } </p>
        <p> { translate('cherry_recipe_p14') } </p>
        <p> { translate('cherry_recipe_p15') } </p>
        <p> { translate('cherry_recipe_p16') } </p>
        <p> { translate('cherry_recipe_p17') } </p>
        <p> { translate('cherry_recipe_p18') } </p>
        <p> { translate('cherry_recipe_p19') } </p>
        <p> { translate('cherry_recipe_p20') } </p>
        <h2> { translate('secondary_fermentation') }: { translate('flavor_combos') } </h2>
        <p> { translate('cherry_recipe_p21') } </p>
        <h2> { translate('bottling') } </h2>
        <img src={MeadPreBottle} alt="cherry mead finished product" style={tempImg2}/>
        <p> { translate('bottling_accessories') } </p>
        <br/>
        <h3> { translate('vanillia') } </h3>
        <img src={VanMead} alt="vanilla mead finished product" style={{height:"50%", width: "50%"}}/>
        <p> { translate('cherry_recipe_p22') } </p>
        <p> { translate('cherry_recipe_p23') } </p>
        <p> { translate('cherry_recipe_p24') } </p>
        <p> { translate('highest_recommendation') } </p>
        <h3> { translate('extra_cherries') } </h3>
        <img src={CherryMead} alt="cherry mead finished product" style={{height:"40%", width: "40%"}}/>
        <p> { translate('cherry_recipe_p25') } </p>
        <p> { translate('high_recommendation') } </p>
        <h3> { translate('coffee') }  </h3>
        <img src={CoffeeMead} alt="coffee mead finished product" style={{height:"40%", width: "40%"}}/>
        <p> { translate('cherry_recipe_p26') }  </p>
        <p> { translate('cherry_recipe_p27') }  </p>
        <p> { translate('cherry_recipe_p28') }  </p>
        <p> { translate('highest_recommendation') } </p>
        <h3> { translate('chocolate') }  </h3>
        <img src={ChocMead} alt="chocolate mead finished product" style={{height:"40%", width: "40%"}}/>
        <p> { translate('cherry_recipe_p29') }  </p>
        <p> { translate('cherry_recipe_p30') }  </p>
        <p> { translate('conditional_recommendation') } </p>
        <h3> { translate('control') } ({ translate('no_flavs') } ) </h3>
        <p> { translate('cherry_recipe_p31') }  </p>
        <p> { translate('cherry_recipe_p32') } </p>
      </div>
      </div>
    </I1BnProvider>
    );
  }
}

function mapStateToProps(state) {
  console.log('state.activeLanguage', state.activeLanguage);
  return {
      active_lang: state.activeLanguage
  };
}

export default connect(mapStateToProps)(Cherry);
