import React, {Component} from 'react';


class Footer extends Component {
  render() {

const footer = {
    backgroundColor: 'rgb(28, 51, 92)'
}

  return(
    <footer style={footer} className="Site-footer">
       <span className="text-muted"> © Alexander Singleton 2021. All Rights Reserved. </span>
    </footer>
   );
  }
}

export default Footer;
