import React, { Component } from 'react';
import { Nav, Navbar, NavDropdown} from 'react-bootstrap';
import { Link,  NavLink } from 'react-router-dom';
import LangSwitcher from '../containers/lang-switcher.js';
// Redux
import { connect } from 'react-redux';
// Multi Langauage Support
import { I1BnProvider } from '../i18n';
import translate from '../i18n/translate.js';

// <Nav.Link href="/datascience">{ translate('navBar_data') }</Nav.Link>

class HomeNavBar extends Component {
  render() {
    return(
    <I1BnProvider locale={this.props.active_lang.locale_val}>
    <header>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Link to="/">{ translate('navBar_homepage') }</Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <Link className="NavbarLink" to="/">{ translate('navBar_homepage') }</Link>
            <Link className="NavbarLink" to="/About">{ translate('navBar_about') }</Link>
            <Link className="NavbarLink" to="/cryptocurrency">{ translate('navBar_crypto') }</Link>
            <NavDropdown
              className="NavbarLink"
              title=<Link style={{paddingLeft: '0', paddingRight: '0.5vw'}}
              className="NavbarLink" to="/mead">{ translate('navBar_mead') }</Link>
              id="basic-nav-dropdown">
             <NavDropdown.Item>
              <NavLink to="/mead/recipes" activeClassName="selected">
                Recipes
              </NavLink> </NavDropdown.Item>
             <NavDropdown.Item>Tips and Tricks</NavDropdown.Item>
             <NavDropdown.Item> <NavLink to="/mead/history" activeClassName="selected"> Mead History </NavLink> </NavDropdown.Item>
            </NavDropdown>
            <Link className="NavbarLink" to="/contact">{ translate('navBar_contact') }</Link>
            <div> <LangSwitcher /> </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
    </I1BnProvider>
    );
  }
};

function mapStateToProps(state) {
  console.log('state.activeLanguage', state.activeLanguage);
  return {
      languages: state.languages
    , active_lang: state.activeLanguage
  };
}

export default connect(mapStateToProps)(HomeNavBar);
