import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
// Redux
import { connect } from 'react-redux';
// Multi Langauage Support
import { I1BnProvider } from '../../i18n';
import translate from '../../i18n/translate.js';
// images
import Background from '../../images/face_shot_cropped.jpg';

class Hero extends Component  {
  render(){

    const headshotImg = {
      width: '20%',
      height: '53%',
      float: 'right',
      marginTop: '2%',
      marginRight: '3%'
    }

    const underscore = {
      minWidth: '88px',
      minHeight: '5px',
      maxWidth: '250px',
      maxHeight: '5px',
      margin: '0 auto',
      marginBottom: '22px',
      backgroundColor: 'black',
      display: 'flex',
      justifyContent: 'center'
    }

    const nameStyle = {
      marginTop:'7%',
      textTransform: 'uppercase',
      fontSize: '38px',
      padding: '15px 0px',
      textAlign: 'center',
      letterSpacing: '0px'
    }

    return (
      <div style={{minHeight: '325px'}}>
      <img src={Background} alt="Headshot of Alexander Singleton" style={headshotImg}/>
      <div style={{textAlign: 'center'}}>
      <br/>
      <h3 style={{marginTop: '2%'}}>
        { translate('welcome') }
      </h3>
      <h2 style={nameStyle}> Alexander Singleton </h2>
      <div style={underscore}></div>
       <h3>   { translate('financial_engineer') } - { translate('author') } - { translate('mead_maker') } </h3>
      </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  console.log('state.activeLanguage', state.activeLanguage);
  return {
      active_lang: state.activeLanguage
  };
}

export default connect(mapStateToProps)(Hero);
