import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
// Redux
import { connect } from 'react-redux';
// Multi Langauage Support
import { I1BnProvider } from '../../i18n';
import translate from '../../i18n/translate.js';

class AboutHome extends Component  {
  render(){
    return (
      <I1BnProvider locale={this.props.active_lang.locale_val}>
        <div style={{textAlign: '-webkit-auto', marginTop: '28%'}}>
          <h3> { translate('navBar_about') } Alexander </h3>
            <p> { translate('alex_is') } </p>
            <p> { translate('author_of') } </p>
            <p> { translate('also_meadmaker') } </p>
        </div>
      </I1BnProvider>
    );
  }
}

function mapStateToProps(state) {
  console.log('state.activeLanguage', state.activeLanguage);
  return {
      active_lang: state.activeLanguage
  };
}

export default connect(mapStateToProps)(AboutHome);
