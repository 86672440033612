import { LOCALES } from '../i18n';

const default_lang =
   {
        id:         'en'
      , lang:       'en'
      , other_lang: 'pl'
      , locale_val: LOCALES.ENGLISH
   }

export default function (state=default_lang, action) {
    switch(action.type) {
      case 'LANG_SWITCH':
        console.log('recieved listener for LANG_SWITCH');
        return action.payload;
      break;
    }
    return state;
}
