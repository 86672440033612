const default_selection = false

export default function (prior_state=default_selection, action) {
    switch(action.type) {
      case 'MODAL_CHANGE':
        console.log('recieved listener for MODAL_CHANGE');
        return {
                ...prior_state,
                payload : action.payload
              };
      default:
      return prior_state;
    }
};
