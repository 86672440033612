import { combineReducers } from 'redux';
import langReducer from './reducer-lang.js';
import activeLanguageReducer from './reducer-active-lang.js';
import closeBannerReducer from './reducer-close-banner.js';
import modalChangeReducer from './reducer-modal-state.js';

const allReducers = combineReducers({
  languages: langReducer,
  activeLanguage: activeLanguageReducer,
  closeBanner: closeBannerReducer,
  modalChange: modalChangeReducer
});

export default allReducers;
