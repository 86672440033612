import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PageNotFound from '../images/digital_bitcoin.jpg';

class NotFoundPage extends Component {
    render(){
        return <div>
            <img src={PageNotFound}  />
            <p style={{textAlign:"center"}}>
              <Link to="/">Go to Home </Link>
            </p>
          </div>;
    }
}
export default NotFoundPage;
