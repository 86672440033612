// Redux Actions
export const changeLanguage = (language) => {
  console.log('Logged call to switch Language to: ', language.other_lang);
  return {
    type: 'LANG_SWITCH',
    payload: language
  }
};

export const closeBanner = (close) => {
  console.log('Logged call to close banner: ', close);
  return {
    type:'CLOSE_BANNER',
    payload: close
  }
};

export const changeModal = (change) => {
  console.log('Logged call to change modal state: ', change);
  return {
    type:'MODAL_CHANGE',
    payload: change
  }
};
