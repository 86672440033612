import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
// import BottomNav from './BottomNav.jsx';
// import Background from '../images/lionfish_1.jpg';
import '../App.css';
import ContactOtherForm from './ContactOtherForm.js'
// Redux
import { connect } from 'react-redux';
// Multi Langauage Support
import { I1BnProvider } from '../i18n';
import translate from '../i18n/translate.js';

class Contact extends Component  {
  render(){

    const underscore = {
      marginTop: '5px',
      minWidth: '88px',
      minHeight: '5px',
      maxWidth: '88px',
      maxHeight: '5px',
      backgroundColor: 'rgb(255, 255, 255)',
    }

    return (
      <I1BnProvider locale={this.props.active_lang.locale_val}>
      <div className="leftAlignedPage" style={{paddingBottom:"0%", paddingTop:"3%"}}>
              <div >
                <h1> { translate('navBar_contact') } </h1>
                <div style={underscore}> </div>
                <h2> testemail@protonmail.com </h2>
              </div>
              <p> { translate('contact_other_inquiries') } </p>
            </div>
             <ContactOtherForm />
    </I1BnProvider>
    );
  }
}

function mapStateToProps(state) {
  console.log('state.activeLanguage', state.activeLanguage);
  return {
      active_lang: state.activeLanguage
  };
}

export default connect(mapStateToProps)(Contact);
