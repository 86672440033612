import { LOCALES } from '../locales.js';

export default {
  [LOCALES.ENGLISH]: {
    'welcome': 'Welcome! This is my professional website.',
    'navBar_homepage': 'Home',
    'navBar_crypto': 'Cryptocurrency',
    'navBar_data': 'Data Science',
    'navBar_mead': 'Mead Making',
    'navBar_contact': 'Contact',
    'navBar_about': 'About',
    'author': 'Author',
    'books': 'Books',
    'polish_first_translation_title': 'Klonowic\'s Ode to Ruthenian Mead',
    'original_polish': 'Original Polish',
    'english_translation': 'My English Translation',
    'all_history_blogs': 'All Mead History Blog Posts',
    'polish_history_blogs': 'Polish mead history',
    'english_history_blogs': 'Old English (Anglo-Saxon) History',
    'crypto_blog_title': 'Cryptocurrency Blog',
    'crypto_experience': 'My experience in research and investment in cryptocurrencies spans over 3 years',
    'all_mead_recipes': 'All Mead Recipes',
    'financial_engineer': 'Financial Engineer',
    'mead_maker': 'Mead Maker',
    'cherry_mead_title': 'Cherry Mead',
    'ingredients': 'Ingredients',
    'cherry_ing_honey': '15 lbs Premium Honey',
    'cherry_ing_frozen_cherry': '4 lbs Frozen Tart Cherries',
    'cherry_ing_fresh_cherry': '2 lbs Fresh Cherries',
    'cherry_ing_pits': 'Handful of Cherry Pits',
    'cherry_ing_vanillia': 'Raw Vanilla Beans (And small amount of vodka)',
    'cherry_ing_coffee': 'Coffee Beans',
    'cherry_ing_choc': 'Cocoa Powder',
    'cherry_ing_extra_honey': 'Extra Honey',
    'cherry_ing_opt_flavors': 'Optional Flavorings For Flavor Combination Fun:',
    'preparation': 'Preparation',
    'primary_fermentation': 'Primary Fermentation',
    'secondary_fermentation': 'Secondary Fermentation',
    'flavor_combos': 'Flavor Combinations',
    'vanillia': 'Vanillia',
    'coffee': 'Coffee',
    'extra_cherries': 'Extra Cherries',
    'chocolate': 'Chocolate',
    'control': 'Control',
    'no_flavs': 'No Extra Flavors',
    'bottling': 'Bottling',
    'bottling_accessories':'For bottling, I sealed with burgundy wax. For corks, I used the type \'number 9\' x 1-1/2 inch long. They are made of natural cork, and were advertised as providing 3-5 years of aging protection. ',
    'cherry_recipe_p1': 'The main inspiration for this mead is the Polish "Wiśniak", a mead made from cherries. I was drawn to the Dwójniak style , but I changed to trójniak for reasons you\'ll soon learn. I\'ve produced meads in a dry style up until now, so this seemed like an excellent recipe to go sweeter.',
    'cherry_recipe_p2': 'As a general point of reference, I used world-renowned Mead Master Ken Schramm\'s Cherry Melomel recipe from his book "The Compleat Meadmaker" to size out the amount of cherries needed.',
    'cherry_recipe_p3': 'I particularly liked his idea of using some cherry pits to impart a "barrel aged" taste. Using the cherries also strikes me as more traditional, since I imagine people were less likely to waste the cherry pits in old times. I\'ve recently sampled some fantastic barrel aged wines and beers, so experimenting with the wooden cores sounded like a good test.',
    'cherry_recipe_p4': 'As with any food or drink, the ingredients are critical. I went to a get locally produced honey at RJ Honey.',
    'cherry_recipe_p5': 'I used the "No Heat Method" like for most of my meads. When people ask me why, I respond that absolutley none of the finest wine makers heat their grapes during the preparation process. It makes little sense not imitate the greatest in this regard The theory is that the heating process allows some flavor to escape, and potentially some of the nutritional compunds may be destroyed by boiling heat',
    'cherry_recipe_p6': 'After adding a gallon of water to my primary fermenter, I add half of the honey and all of the cherries. I also threw in a dash of cherry pits.',
    'cherry_recipe_p7': 'Following this, I add the rest of the honey and fill the water to the 5 gallon mark',
    'cherry_recipe_p8': 'Adding the yeast energizer, yeast nutrient, and then pitch the yeast itself.',
    'cherry_recipe_p9': 'Shaking and stirring it like a madman lets oxygen bubble into the must. This step is crucial since the yeast needs an oxygenated environment to wake up and thrive. Don\'t worry, the yeast will eat up the oxygen before it can turn the wine stale',
    'cherry_recipe_p10': 'As part of due diligence, I periodically check the mead for evidence of significant bubbling through the airlock. This is proof that the yeast is living well since happy yeast produces C02 and alcohol',
    'cherry_recipe_p11': 'At the beginning of the third day, I began to suspect that the fermentation had not started. There were occasional bubbles in the airlock, but nothing steady or increasing',
    'cherry_recipe_p12': 'I suspect that the increased sugar from the extra cherries caused the yeast to struggle in their first few days. As per Ken Schramm\'s instructions, I acted calmly yet decisively to address this.',
    'cherry_recipe_p13': 'First, I added more water by filling water up to the 6.5 gallon mark (nearly to the top).',
    'cherry_recipe_p14': 'Then, I added some yeast energizer and nutrient to give the yeast another jump start',
    'cherry_recipe_p15': 'Shaking it up again, I waited with some anticipation for signs of success',
    'cherry_recipe_p16': 'For an entire day, nothing happened.',
    'cherry_recipe_p17': 'Then, like a miracle the bubbling started just like all the other great meads I\'ve made!',
    'cherry_recipe_p18': 'My hypothesis is that all the extra cherries simply gave the yeast a a hard time in such a sugar rich environment. Imagine trying to get out of bed if your bedroom was covered floor to ceiling with a buffet. Would be hard to get your day started, right?',
    'cherry_recipe_p19': 'Additionally, the moderate d47 yeast I choose is generally used for recipes that start with an average sugar contents',
    'cherry_recipe_p20': 'However, the high sugar density likely would have been suficient for even high sugar and alcohol tolerant yeast Like lalvin 1118 or Wyeast Dry Yeast, which is the yeast Mr. Schramm recommends. This makes me think the yeast choice also played a role, but I\'d need to confirm by recreating this recepie with other yeast types',
    'cherry_recipe_p21': 'I taste tested at 7 Months after the beginning of primary fermentation',
    'cherry_recipe_p22': 'I used half a Vanillia bean, split and rested in shot of vodka. After a night in the refrigerator, I added the shot to a 1 gallon glass carboy of cherry mead.',
    'cherry_recipe_p23': 'The Vodka was smirnoff caramel, which added a hint of flavor that turned out great.',
    'cherry_recipe_p24': 'Also I added 5.5 oz of sweet cherries with pits removed',
    'cherry_recipe_p25': 'I added 8.5 oz of sweet cherries with pits removed. I thought this was better than the control. Has a distinct "wine like" mouthfeel.',
    'cherry_recipe_p26': 'Added half a grinder of orgainic coffee to water, then cold brewed overnight in 2 cups of water.',
    'cherry_recipe_p27': 'In the morning, I added it to a half gallon carboy.',
    'cherry_recipe_p28': 'It had a very appealing aroma, well balanced bitter flavor offseting the sweetness, and interesting scotch-like tint to color gives a distinguished appearence',
    'cherry_recipe_p29': 'The inspiration for this is the chocolate covered cherry treat. I used 0.35oz of Hershey\'s tm cocoa powder',
    'cherry_recipe_p30': 'Unfortunately, it didn\'t end up adding much flavor or appealing scents. Most of cocoa powder ended up settling at the bottom of the carboy.',
    'cherry_recipe_p31': 'I\'m glad I experimented with the amount of cherries and these different flavors.',
    'cherry_recipe_p32': 'I think it turned out fantastically, what do you think? Will you try some similar recipes?',
    'highest_recommendation':'Personal Recommendation Score: 5/5 - Highly Recommend',
    'high_recommendation':'Personal Recommendation Score: 4/5 - Recommend',
    'conditional_recommendation': 'Personal Recommendation Score: 2/5 - Conditional Recommendation for lovers of this ingredient',
    'free_pocket_edition_link': 'Get a complimentary copy of the pocket edition here',
    'contact_other_inquiries': 'For all other inquiries, please use the form below!',
    'mead_quote': '"Wine comes from the earth muddy and grey, Mead must be sent down straight from the heavens"',
    'what_is_mead': 'Mead is a type of wine, but made with honey instead of grapes',
    'mead_fan': 'I\'m a big fan of regular wine too',
    'mead_experience': 'I have over 7 years of experience in the production of mead with recipes',
    'check_out_book': 'Update: Check out my new book "The Complete Cryptocurrency Investor"',
    'this_is_crypto_blog': 'This is my blog for my personal cryptocurrency adventures, opinions and thought',
    'looking_for_co_website': 'If you are looking for my dedicated cryptocurrency investment website where my book and other educational content is, please visit:',
    'will_leave_site': 'will leave the site',
    'alex_is': 'Alexander is a Financial Technology professional and author, with a hobby in amateur Mead making',
    'author_of': 'Author of the book The Complete Cryptocurrency Investor, his passion is to help people get started in the world of Bitcoin and cryptocurrency investment.',
    'also_meadmaker': 'When he is not working on tech projects, he\'s tinkering with mead recepies. His goal is to restore Mead\'s prestigious place among other wines.',
    'his_education':'His education is a Bachelors in Operations Research & Financial Engineering from Princeton University.',
    'he_works_at':'He currently works as an Analytics Engineer at Cyndx Technologies, a software platform for discovering venture capital, M&A and similar investment opportunities. His prior industry experience includes password security and search engine optimization applications.',
    'this_translation': 'This translation comes from the book “The Lands of Red Ruthenia" written by Pole Sebastian Fabian Klonowic in the late 1500’s.',
    'he_spent': 'He spent considerable time in Lwów and so is a qualified ethnographer of the region.',
    'written_first': 'Written first in Latin, but translated into Polish and published in Wilno as early as 1851.',
    'heres_excerpt': 'Here’s an example from chapter XXIV:',
  }
}
