import React, { Component } from 'react';
import Footer from './Footer.jsx';
import {Helmet} from "react-helmet";
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
// Redux
import { connect } from 'react-redux';
// Multi Langauage Support
import { I1BnProvider } from '../i18n';
import translate from '../i18n/translate.js';

class Mead extends Component {
  render() {
    return (
    <I1BnProvider locale={this.props.active_lang.locale_val}>
      <div>
        <Helmet>
           <title> Mead Making | Recipes and Craftsmanship Blog | Alexander Singleton </title>
           <link rel="canonical" href="https://www.alexanderjohnsingleton.com/mead" />
        </Helmet>
        <div className="Basic-page-layout">
          <div style={{minHeight: '325px'}}>
            <Fade top>
            <h5 className="Mead-Quote" style={{paddingBottom: "0%"}}> { translate('mead_quote') } </h5>
            <p style={{marginTop: '-5px', fontSize: 'small'}}> - Sebastian Klonowic (Author, 1580s) </p>
            </ Fade>
            <div className="leftAlignedPage" style={{paddingTop: "0%"}}>
             <p> { translate('mead_fan') } </p>
              <p> { translate('what_is_mead') } </p>
              <p>
                { translate('mead_experience') }:
              </p>
              <NavLink to="/mead/recipes" activeClassName="selected">
                       Recipes
              </NavLink>
              </div>
          </div>
        </div>
      </div>
    </I1BnProvider>
    );
  }
}

function mapStateToProps(state) {
  console.log('state.activeLanguage', state.activeLanguage);
  return {
      active_lang: state.activeLanguage
  };
}

export default connect(mapStateToProps)(Mead);
