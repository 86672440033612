import { LOCALES } from '../i18n';

export default function() {
  return [
     {
          id:         'en'
        , lang:       'en'
        , other_lang: 'pl'
        , locale_val: LOCALES.ENGLISH
     },
     {
          id:         'pl'
        , lang:       'pl'
        , other_lang: 'en'
        , locale_val: LOCALES.POLISH
     }
  ]
}
