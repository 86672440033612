import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import '../App.css';
// Redux
import { connect } from 'react-redux';
// Multi Langauage Support
import { I1BnProvider } from '../i18n';
import translate from '../i18n/translate.js';

class Resume extends Component  {
  render(){
    return (
      <I1BnProvider locale={this.props.active_lang.locale_val}>
        <div>
            <p> Please find my resume below </p>
            <object data="resume.pdf" type="application/pdf" width="100%" height="800px">
            <p> Feel free to download my resume in PDF format <a href="resume.pdf">by clinking here</a></p>
            </object>
          <embed src="your.pdf" type="application/pdf#view=FitH" width="actual-width.px" height="actual-height.px"></embed>
          <p> Resume Last updated Feb 21st, 2021</p>
          <p> translation placeholder { translate('navBar_homepage') } </p>
        </div>
      </I1BnProvider>
    );
  }
}

function mapStateToProps(state) {
  console.log('state.activeLanguage', state.activeLanguage);
  return {
      active_lang: state.activeLanguage
  };
}

export default connect(mapStateToProps)(Resume);
