import React, { Component } from 'react';
import {Helmet} from "react-helmet";
// Redux
import { connect } from 'react-redux';
// Multi Langauage Support
import { I1BnProvider } from '../../i18n';
import translate from '../../i18n/translate.js';

class KlonowicOne extends Component {
  render() {
    return (
    <I1BnProvider locale={this.props.active_lang.locale_val}>
    <div>
      <Helmet>
         <title> Mead Making | History Blog | Alexander Singleton </title>
         <link rel="canonical" href="https://www.alexanderjohnsingleton.com/mead/history/polish-mead-translation" />
      </Helmet>
      <h1 style={{paddingTop: "3%"}}> {translate('polish_first_translation_title')} </h1>
      <div style={{paddingTop: "0%"}}>
      <div className="leftAlignedPage" style={{paddingTop: "4%", paddingBottom:"2%"}}>
        <p> {translate('this_translation')} </p>
        <p> {translate('he_spent')} </ p>
        <p> {translate('written_first')} </p>
        <p> { translate('heres_excerpt') } </p>
      </div>
        <div style={{display: "inline-flex", textAlign: "left", padding: "2%"}}>
          <div>
            <h4> { translate('original_polish') } </h4>
            <p> ...Miód z chmielną wodą kiedy się rozsyci, </p>
            <p>    Tworzy się napój posilny i zdrowy. </p>
            <p>    Włoski mieszkańcze! cóż twoje nektary? </p>
            <p>    Co twoje wina przed nektarem Rusi? </p>
            <p>    Wino jest z ziemi błotnistej i szarej, </p>
            <p>    Miód prosto z niebios spuszczony być musi. </p>
            <p>    Miód rosa niebios i manna jedyna — </p>
            <p>    Pszczółka ją zbiera do swego zacisza; </p>
            <p>    Miody sycone lepsze są od wina, </p>
            <p>    O ile Bachus niższy od Jowisza, </p>
            <p>    Jak wyższe niebo nad poziom ubogi, </p>
            <p>    Skąd się wyradza winogronna trawa. </p>
            <p>    Miodem zaiste nieśmiertelne bogi </p>
            <p>    Kwitnąca Hebe w Olimpie napawa... </p>
          </div>
          <div style={{marginLeft: "75px"}}> </div>
          <div>
            <h4 > { translate('english_translation') } </h4>
            <p> ...When hopped water is steeped in honey,  </p>
            <p> it makes [mead] - a drink healthy and strong.  </p>
            <p> Oh Italians! What nectar is yours? </p>
            <p>	What is your wine before the nectars of Ruthenia? </p>
            <p> While wine comes from the earth muddy and ashen, </p>
            <p> mead must descend directly from the heavens. </p>
            <p> Honey is heavenly dew and the only manna, </p>
            <p> The bee, she collects it to her hidden hive; </p>
            <p> Sweetened meads are better than wine, </p>
            <p> like Bacchus is inferior to Jupiter, </p>
            <p> like how higher heaven is to our humble middle earth, </p>
            <p> where the grape vines take root. </p>
            <p> Indeed the immortal god Hebe, Olympus’ [cupbearer], </p>
            <p> she scintillates with thoughts of mead’s decadent delights... </p>
          </div>
        </div>
      </div>
      <p className="text-muted"> Last Updated: October 2nd, 2021 </p>
    </div>
    </I1BnProvider>
      );
    }
  }

  function mapStateToProps(state) {
    console.log('state.activeLanguage', state.activeLanguage);
    return {
        active_lang: state.activeLanguage
    };
  }

  export default connect(mapStateToProps)(KlonowicOne);
