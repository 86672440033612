import React, { Component } from 'react';
import { Form } from 'react-bootstrap';

class ContactOtherForm extends Component {
  render() {
    return(
      <Form className="leftAlignedPage" style={{paddingTop:"1%"}}>
        <Form.Group controlId="contactForm.emailInput">
          <Form.Label>Email Address *</Form.Label>
          <Form.Control type="email" placeholder="name@example.com" />
        </Form.Group>
        <Form.Group controlId="contactForm.subjectText">
          <Form.Label>Subject *</Form.Label>
          <Form.Control type="text" placeholder="Request for ..."/>
        </Form.Group>
        <Form.Group controlId="contactForm.messageText">
          <Form.Label>Message *</Form.Label>
          <Form.Control as="textarea" rows={3} placeholder="What is your availability for ..."/>
        </Form.Group>
      </Form>
      );
    }
  };

export default ContactOtherForm;
