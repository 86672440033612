import React, { Component }  from "react";
import { Modal, Button } from 'react-bootstrap';
// Redux
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';
import { changeModal } from '../actions/index.js';

// handleAmazonLink(() => {
//     console.log('Link to Amazon Activated')
//   );
//this.props.changeModal(false)

//<Modal show={this.props.closed_modal} onHide={this.logHideBehavior}>

class BookModal extends Component {

handleAmazonLink = (e) => {
  console.log('Link to Amazon Activated', e)
}

logHideBehavior = () => {
  console.log('Logged hide behavior')
  this.props.changeModal(false)
}

delayedModalPopup = () => {
    console.log('Logged Show behavior')
    setTimeout(() => this.props.changeModal(true), 5000)
}

componentDidMount() {
  this.delayedModalPopup()
}

 // componentWillUnmount() {
 //   clearTimeout(this.t);
 // }

  render() {
      return (
      <div>
        <Modal show={this.props.closed_modal.payload} onHide={() => this.props.changeModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Get My New Book!</Modal.Title>
          </Modal.Header>
          <Modal.Body> Alexander Singleton with KDP Publishing proudly presents
                          "The Complete Cryptocurrency Investor"
                         Available as an Ebook, soft or hardcover.
           <Button variant="primary" onClick={this.handleAmazonLink}>
                   Order
             Complete Cryptocurrency
                   Investor
           </Button>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  console.log('state.changeModal', state.modalChange);
  return {
      languages: state.languages
    , active_lang: state.activeLanguage
    , closed_modal: state.modalChange
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({changeModal: changeModal}, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(BookModal);
