import React, { Component } from 'react';
import {Helmet} from "react-helmet";
// Local containers
import LangSwitcher from './lang-switcher.js';
// Local component
import Hero from '../components/HomeComponents/Hero.js';
import AboutHome from '../components/HomeComponents/AboutHome.js';
import BookHome from '../components/HomeComponents/AboutHome.js';
import TopicNavTriplet from '../components/HomeComponents/TopicNavTriplet.js';
// Multilanguage support
import { I1BnProvider } from '../i18n';
 // Redux
 import { connect } from 'react-redux';

class Home extends Component {
  render () {
    return (
      <I1BnProvider locale={this.props.active_lang.locale_val}>
      <div className="centeredPage">
        <Helmet>
           <title> Alexander Singleton | Professional Website Home </title>
           <link rel="canonical" href="https://www.alexanderjohnsingleton.com/" />
        </Helmet>
        <Hero />
        <TopicNavTriplet />
        <AboutHome />
      </div>
    </I1BnProvider>
    );
  }
};

function mapStateToProps(state) {
  console.log('state.activeLanguage', state.activeLanguage);
  return {
      active_lang: state.activeLanguage
  };
}

export default connect(mapStateToProps)(Home);
