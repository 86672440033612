import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
// Redux
import { connect } from 'react-redux';
// Multi Langauage Support
import { I1BnProvider } from '../i18n';
import translate from '../i18n/translate.js';

class Recipes extends Component {
  render() {
    return (
    <I1BnProvider locale={this.props.active_lang.locale_val}>
      <div>
        <Helmet>
           <title> Mead Making | Recipes and Craftsmanship Blog | Alexander Singleton </title>
           <link rel="canonical" href="https://www.alexanderjohnsingleton.com/mead/recipes" />
        </Helmet>
        <div className="Basic-page-layout">
        < h1 style={{marginTop: "5%"}}> { translate('all_mead_recipes') }  </h1>
        <div style={{textAlign: "-webkit-left", padding: "8%", fontSize: "x-large"}}>
        <Link to="/mead/recipes/cherry" activeClassName="selected" >
            {translate('cherry_mead_title')}
        </Link>
        </div>
        </div>
      </div>
    </I1BnProvider>
    );
  }
}

function mapStateToProps(state) {
  console.log('state.activeLanguage', state.activeLanguage);
  return {
      active_lang: state.activeLanguage
  };
}

export default connect(mapStateToProps)(Recipes);
