import React, { Component } from 'react';
import {Helmet} from "react-helmet";

class DataScience extends Component {
  render() {
    return (
      <div className="Mead">
        <Helmet>
           <title> Data Science | NLP and Ancient Language Decoding Blog | Alexander Singleton </title>
           <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <header className="Mead_header">
          <p>
            The Voynich Manuscript: A 14th century tome written in a currently undechipered language
          </p>
          <p>
            Using modern Natural Language Processing techniques, I will step by step attempt to decode this mysterious enigma.
          </p>
          <p>
            Here's more background and my test approach, where I try to recreate Shakespere's English. Do I succeed?
          </p>
        </header>
      </div>
    );
  }
}

export default DataScience;
