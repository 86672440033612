import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
// Redux
import { connect } from 'react-redux';
// Multi Langauage Support
import { I1BnProvider } from '../i18n';
import translate from '../i18n/translate.js';

class History extends Component {
  render() {
    return (
    <I1BnProvider locale={this.props.active_lang.locale_val}>
      <div>
        <Helmet>
           <title> Mead Making | History Blog | Alexander Singleton </title>
           <link rel="canonical" href="https://www.alexanderjohnsingleton.com/mead/history" />
        </Helmet>
        <div className="Basic-page-layout">
        <h1 style={{marginTop: "5%"}}> { translate('all_history_blogs') }  </h1>
        <div style={{textAlign: "-webkit-left", padding: "8%", fontSize: "x-large"}}>
        <h3> { translate('polish_history_blogs') } </h3>
        <Link to="/mead/history/polish-mead-translation" activeClassName="selected" >
            {translate('polish_first_translation_title')}
        </Link>
        <h3 style={{marginTop: "5%"}}> { translate('english_history_blogs') } </h3>
        <h4> Coming soon... </h4>
        <p> Introduction to Beowulf - Mead's Foundational Role </p>
        <p> Old Tales of Baltic Europe: Anglo Saxon Encounters </p>
        </div>
        </div>
      </div>
    </I1BnProvider>
    );
  }
}

function mapStateToProps(state) {
  console.log('state.activeLanguage', state.activeLanguage);
  return {
      active_lang: state.activeLanguage
  };
}

export default connect(mapStateToProps)(History);
