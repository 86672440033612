import './App.css';
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import HomeNavBar from './components/HomeNavBar.jsx';
import Footer from './components/Footer.jsx';
import Cryptocurrency from './components/Cryptocurrency.js';
import DataScience from './components/DataScience.js';
import Contact from './components/Contact.js';
import Mead from './components/Mead.js';
import History from './components/History.js';
import PolishBlogOne from './components/AllHistoryBlogs/Klonowic_1.js';
import Recipes from './components/Recipes.js';
import Cherry from './components/AllRecipes/Cherry.js';
import ContainerHome from './containers/Home.js';
import LangSwitcher from './containers/lang-switcher.js';
import BookBanner from './components/BookBanner.js';
import BookModal from './components/BookModal.js';
import NotFoundPage from './components/NotFoundPage.js';
import About from './components/About.js';
import Resume from './components/Resume.js';

// <Route exact path='/datascience' component={DataScience} />
// Temp extraction // <BookModal />
//  <BookBanner />

class App extends Component {
  render() {
    return (
      <div className='Site-content'>
          <Router>
            <HomeNavBar />
              <Switch>
                  <Route exact path='/' component={ContainerHome} />
                  <Route exact path='/about' component={About} />
                  <Route exact path='/about/resume' component={Resume} />
                  <Route exact path='/cryptocurrency' component={Cryptocurrency} />
                  <Route exact path='/mead' component={Mead} />
                  <Route exact path='/contact' component={Contact}/>
                  <Route exact path='/mead/recipes' component={Recipes} />
                  <Route exact path='/mead/recipes/cherry' component={Cherry} />
                  <Route exact path='/mead/history' component={History} />
                  <Route exact path='/mead/history/polish-mead-translation' component={PolishBlogOne} />
                  <Route path="/404" component={NotFoundPage} />
                  <Redirect to="/404" />
                </Switch>
          </Router>
        <Footer />
      </div>
    );
  }
}

export default App;
