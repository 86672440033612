import React, { Component } from 'react';

import {Helmet} from "react-helmet";
// Redux
import { connect } from 'react-redux';
// Multi Langauage Support
import { I1BnProvider } from '../i18n';
import translate from '../i18n/translate.js'

// TODO: Return to class when book available
// <p>
//     { translate('check_out_book') }
// </p>
// <p>
//   {translate('free_pocket_edition_link')} []
// </p>

class Cryptocurrency extends Component {
  render() {
    return (
      <I1BnProvider locale={this.props.active_lang.locale_val}>
        <div className="leftAlignedPage">
          <Helmet>
             <title> Cryptocurrency | Books and Blog | Alexander Singleton </title>
             <link rel="canonical" href="https://www.alexanderjohnsingleton.com/cryptocurrency" />
          </Helmet>
          <main>
            <h1> { translate('crypto_blog_title') } </h1>
            <p>
              { translate('crypto_experience') }
            </p>
            <p>
                { translate('this_is_crypto_blog') }
            </p>
            <p>
                { translate('looking_for_co_website') }
              <br/>
              <a href="https://www.cryptocurrencyinvestments.co">
                cryptocurrencyinvestments.co
              </a>
              <br/>
                ({ translate('will_leave_site') })
            </p>
          </main>
        </div>
      </I1BnProvider>
    );
  }
}

function mapStateToProps(state) {
  console.log('state.activeLanguage', state.activeLanguage);
  return {
      active_lang: state.activeLanguage
  };
}

export default connect(mapStateToProps)(Cryptocurrency);
