import { LOCALES } from '../locales.js';

export default {
  [LOCALES.POLISH]: {
    'welcome': 'Witamy! To jest moja profesjonalna strona internetowa',
    'navBar_homepage': 'Strona Główna',
    'navBar_crypto': 'Kryptowaluta',
    'navBar_data': 'Analityka Danych',
    'navBar_mead': 'Miód Pitny',
    'navBar_contact': 'Kontakt',
    'navBar_about' : 'O Sobie',
    'books': 'Książki',
    'author': 'Autor',
    'financial_engineer': 'Inżynier Finansowy',
    'mead_maker': 'Producent Miodu Pitnego',
    'crypto_blog_title': 'Blog dotyczący kryptowalut',
    'cherry_mead_title': 'Wiśniak',
    'all_history_blogs': 'Wszystkie wpisy na blogu o historii miodów pitnych',
    'polish_history_blogs': 'Historia polskich miodów pitnych',
    'english_history_blogs': 'Historia staroangielska (anglosaska)',
    'original_polish': 'Oryginalny Polski',
    'english_translation': 'Moje Angielskie Tłumaczenie',
    'polish_first_translation_title': 'Oda Klonowica do miodu ruskiego',
    'ingredients': 'Składniki',
    'cherry_ing_honey': '15 funtów miodu premium',
    'cherry_ing_frozen_cherry': '4 funty mrożonych wiśni',
    'cherry_ing_fresh_cherry': '2 funty świeżych wiśni',
    'cherry_ing_pits': 'Garść pestek wiśni',
    'cherry_ing_vanillia': 'Surowe ziarna wanilii (i mała ilość wódki)',
    'cherry_ing_coffee': 'Ziarna Kawy',
    'cherry_ing_choc': 'Kakao w Proszku',
    'cherry_ing_extra_honey': 'Dodatkowy miód',
    'cherry_ing_opt_flavors': 'Opcjonalne aromaty dla zabawy łączonej smakiem:',
    'preparation': 'Przygotowanie',
    'primary_fermentation': 'Fermentacja pierwotna',
    'secondary_fermentation': 'Fermentacja wtórna',
    'flavor_combos': 'Kombinacje smakowe',
    'vanillia': 'wanilią',
    'coffee': 'Kawą',
    'extra_cherries': 'Extra Wiśnie',
    'chocolate': 'Czekolada',
    'control': 'Kontrolna',
    'no_flavs': 'Bez dodatkowych aromatów',
    'bottling': 'Rozlew',
    'bottling_accessories': 'Do butelkowania zapieczętowałem burgundowym woskiem. W przypadku korków użyłem typu „liczba 9” x 1-1/2 cala długości. Wykonane są z naturalnego korka i były reklamowane jako zapewniające 3-5 lat ochrony przed starzeniem.',
    'cherry_recipe_p1': 'Główną inspiracją dla tego miodu pitnego jest polski miód pitny „Wiśniak” z wiśni. Pociągał mnie styl dwójniaka, ale przeszedłem na trójniaka z powodów, których wkrótce się dowiesz. Do tej pory produkowałem miody pitne w stylu wytrawnym, więc wydawało mi się, że to doskonały przepis na słodsze.',
    'cherry_recipe_p2': 'Jako ogólny punkt odniesienia użyłem znanego na całym świecie przepisu Cherry Melomel mistrza miodu pitnego Kena Schramma z jego książki „The Compleat Meadmaker”, aby określić ilość potrzebnych wiśni.',
    'cherry_recipe_p3': 'Szczególnie podobał mi się jego pomysł wykorzystania pestek wiśni w celu nadania smaku „beczkowatemu”. Używanie wiśni również wydaje mi się bardziej tradycyjne, ponieważ wyobrażam sobie, że w dawnych czasach ludzie rzadziej marnowali pestki wiśni. Niedawno próbowałem kilku fantastycznych win i piw dojrzewających w beczkach, więc eksperymentowanie z drewnianymi rdzeniami brzmiało jak dobry test.',
    'cherry_recipe_p4': 'Podobnie jak w przypadku każdego jedzenia lub napoju, składniki mają kluczowe znaczenie. Poszedłem do lokalnie produkowanego miodu w RJ Honey.',
    'cherry_recipe_p5': 'Użyłem metody „No Heat Method”, jak w przypadku większości moich miodów pitnych. Kiedy ludzie pytają mnie dlaczego, odpowiadam, że żaden z najlepszych winiarzy nie podgrzewa swoich winogron podczas procesu przygotowania. Nie ma sensu imitować najlepszych w tym względzie Teoria głosi, że proces podgrzewania pozwala na ucieczkę części aromatu i potencjalnie niektóre składniki odżywcze mogą zostać zniszczone przez wrzące ciepło',
    'cherry_recipe_p6': 'Po dodaniu galona wody do mojego głównego fermentora dodaję połowę miodu i wszystkie wiśnie. Dorzuciłem też odrobinę pestek wiśni.',
    'cherry_recipe_p7': 'Następnie dodaję resztę miodu i napełniam wodę do oznaczenia 5 galonów',
    'cherry_recipe_p8': 'Dodanie aktywatora drożdży, pożywki dla drożdży, a następnie samo rozłożenie drożdży.',
    'cherry_recipe_p9': 'Potrząsanie i mieszanie jak szaleniec sprawia, że ​​tlen wpada do moszczu. Ten krok jest kluczowy, ponieważ drożdże potrzebują natlenionego środowiska, aby się obudzić i dobrze się rozwijać. Nie martw się, drożdże pochłoną tlen, zanim zdążą zwietrzyć wino',
    'cherry_recipe_p10': 'W ramach należytej staranności okresowo sprawdzam miód pitny pod kątem oznak silnego bulgotania przez śluzę. To dowód na to, że drożdże żyją dobrze, ponieważ szczęśliwe drożdże produkują CO2 i alkohol',
    'cherry_recipe_p11': 'Na początku trzeciego dnia zacząłem podejrzewać, że fermentacja się nie rozpoczęła. W śluzie od czasu do czasu pojawiały się bąbelki, ale nic nie było stałe ani nie rosło',
    'cherry_recipe_p12': 'Podejrzewam, że zwiększony cukier z dodatkowych wiśni spowodował, że drożdże walczyły w pierwszych kilku dniach. Zgodnie z instrukcjami Kena Schramma, działałem spokojnie, ale zdecydowanie, aby rozwiązać ten problem.',
    'cherry_recipe_p13': 'Najpierw dodałem więcej wody, napełniając ją do poziomu 6,5 galona (prawie do góry).',
    'cherry_recipe_p14': 'Następnie dodałem trochę aktywizatora drożdży i odżywki, aby drożdże ponownie zaczęły działać',
    'cherry_recipe_p15': 'Potrząsając tym ponownie, czekałem z pewnym oczekiwaniem na oznaki sukcesu',
    'cherry_recipe_p16': 'Przez cały dzień nic się nie działo.',
    'cherry_recipe_p17': 'Potem, jak cud, bulgotanie zaczęło się tak jak wszystkie inne wspaniałe miody pitne, które zrobiłem!',
    'cherry_recipe_p18': 'Moja hipoteza jest taka, że ​​wszystkie dodatkowe wiśnie po prostu utrudniały drożdżom pracę w tak bogatym w cukier środowisku. Wyobraź sobie, że próbujesz wstać z łóżka, gdyby Twoja sypialnia była od podłogi do sufitu przykryta bufetem. Trudno byłoby rozpocząć dzień, prawda?',
    'cherry_recipe_p19': 'Ponadto wybrane przeze mnie umiarkowane drożdże d47 są generalnie używane w przepisach, które rozpoczynają się od średniej zawartości cukru',
    'cherry_recipe_p20': 'Jednak wysoka gęstość cukru prawdopodobnie byłaby wystarczająca nawet dla drożdży o wysokiej tolerancji na cukier i alkohol, takich jak lalvin 1118 lub Wyeast Dry Yeast, które są drożdżami zalecanymi przez pana Schramma. To sprawia, że ​​myślę, że wybór drożdży również odegrał rolę, ale muszę potwierdzić, odtwarzając ten przepis z innymi rodzajami drożdży',
    'cherry_recipe_p21': 'Testowałem smak po 7 miesiącach od rozpoczęcia pierwotnej fermentacji',
    'cherry_recipe_p22': 'Ja użyłam pół ziaren wanilii, połamałam i odpoczęłam w kieliszku wódki.',
    'cherry_recipe_p23': 'Wódka to karmel smirnoff, który dodał nutę smaku, który okazał się świetny.',
    'cherry_recipe_p24': 'Dodano także 5,5 uncji czereśni z usuniętymi pestkami',
    'cherry_recipe_p25': 'Dodałam 8,5 uncji czereśni z usuniętymi pestkami. Myślałem, że to lepsze niż kontrola. Ma wyraźny posmak w ustach podobny do wina.',
    'cherry_recipe_p26': 'Do wody dodano pół młynka kawy orgainicznej, a następnie parzono na zimno przez noc w 2 filiżankach wody.',
    'cherry_recipe_p27': 'Dodano do butli o pojemności pół galona. ',
    'cherry_recipe_p28': 'Bardzo atrakcyjny zapach, dobrze zbalansowany smak równoważy słodycz, a ciekawy odcień koloru nadaje wyrazistości',
    'cherry_recipe_p29': 'Inspiracją do tego jest poczęstunek wiśniowy w czekoladzie.',
    'cherry_recipe_p30': 'Niestety, nie dodało to zbyt wiele smaku ani atrakcyjnych zapachów. Większość proszku kakaowego osiadła na dnie balonu.',
    'cherry_recipe_p31': 'Cieszę się, że eksperymentowałem z ilością wiśni i tymi różnymi smakami.',
    'cherry_recipe_p32': 'Myślę, że wyszło dobrze, co o tym myślisz? Czy spróbujesz podobnych przepisów?',
    'highest_recommendation':'Osobisty wynik rekomendacji: 5/5 - Gorąco Polecam',
    'high_recommendation':'Osobisty wynik rekomendacji: 4/5 - Polecam',
    'conditional_recommendation': 'Osobisty wynik rekomendacji: 2/5 - Warunkowe zalecenie dla miłośników tego składnika',
    'all_mead_recipes': 'Wszystkie Przepisy Na Miód Pitny',
    'crypto_experience': 'Moje doświadczenie w badaniach i inwestycjach w kryptowaluty obejmuje ponad 3 lata',
    'contact_other_inquiries': 'W przypadku innych pytań skorzystaj z poniższego formularza',
    'free_pocket_edition_link': 'Uzyskaj bezpłatną kopię wydania kieszonkowego tutaj',
    'mead_quote': '"Wino jest z ziemi błotnistej i szarej, Miód prosto z niebios spuszczony być musi"',
    'what_is_mead': 'Miód pitny to rodzaj wina, ale zrobiony z miodu zamiast winogron',
    'mead_fan': 'Jestem też wielkim fanem zwykłego wina',
    'mead_experience': 'Posiadam ponad 7 letnie doświadczenie w produkcji miodów pitnych z recepturami',
    'check_out_book': 'Aktualizacja: Sprawdź moją nową książkę "The Complete Cryptocurrency Investor"',
    'this_is_crypto_blog': 'To jest mój blog na moje osobiste przygody, opinie i przemyślenia na temat kryptowalut',
    'looking_for_co_website': 'Jeśli szukasz mojej dedykowanej strony internetowej poświęconej inwestowaniu w kryptowaluty, na której znajduje się moja książka i inne treści edukacyjne, odwiedź:',
    'will_leave_site': 'opuści witrynę',
    'alex_is': 'Alexander jest specjalistą i autorem w dziedzinie technologii finansowych, a jego hobby to amatorskie wytwarzanie miodów pitnych',
    'author_of': 'Autor książki The Complete Cryptocurrency Investor, jego pasją jest pomaganie ludziom w wejściu w świat inwestycji w Bitcoin i kryptowaluty.',
    'also_meadmaker': 'Kiedy nie pracuje nad projektami technologicznymi, majstruje przy przepisach na miód pitny. Jego celem jest przywrócenie prestiżowego miejsca Mead wśród innych win.',
    'his_education':'Jego wykształcenie to licencjat z badań operacyjnych i inżynierii finansowej na Uniwersytecie Princeton.',
    'he_works_at':' Obecnie pracuje jako inżynier ds. analityki w Cyndx Technologies, platformie oprogramowania służącej do odkrywania kapitału wysokiego ryzyka, fuzji i przejęć oraz podobnych możliwości inwestycyjnych. Jego wcześniejsze doświadczenie w branży obejmuje aplikacje do zabezpieczania haseł i optymalizacji wyszukiwarek.',
    'this_translation': 'Przekład ten pochodzi z książki „Ziemie Rusi Czerwonej” napisanej przez Polaka Sebastiana Fabiana Klonowica pod koniec XVI wieku.',
    'he_spent': 'Spędził dużo czasu we Lwowie, jest więc wykwalifikowanym etnografem regionu.',
    'written_first': 'Napisany najpierw po łacinie, ale przetłumaczony na język polski i wydany w Wilnie już w 1851 roku.',
    'heres_excerpt': 'Oto przykład z rozdziału XXIV:',
  }
}
