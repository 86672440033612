import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import '../App.css';
// Redux
import { connect } from 'react-redux';
// Multi Langauage Support
import { I1BnProvider } from '../i18n';
import translate from '../i18n/translate.js';

class About extends Component  {
  render(){
    return (
      <I1BnProvider locale={this.props.active_lang.locale_val}>
      <main className="leftAlignedPage">
        <h2> {translate('navBar_about')} </h2>
        <p> { translate('alex_is') } </p>
        <p> { translate('author_of') } </p>
        <p> { translate('also_meadmaker') } </p>
        <p> { translate('his_education') } </p>
        <p> { translate('he_works_at') } </p>
      </main>
      </I1BnProvider>
    );
  }
}

function mapStateToProps(state) {
  console.log('state.activeLanguage', state.activeLanguage);
  return {
      active_lang: state.activeLanguage
  };
}

export default connect(mapStateToProps)(About);
