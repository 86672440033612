import React, { Component } from 'react';
// Redux
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';
import { changeLanguage } from '../actions/index.js';
// language flags
import pol_flag from './poland_flag.png';
import us_flag from './us_flag_cropped.jpg';

class LangSwitcher extends Component {

  findArrayElementById(id, array) {
    return array.find((e) => {
      return e.id === id;
    })
  }

  render() {
      return (
          <div className='Flag-center'>
             { this.props.active_lang.other_lang === 'en' ?
               <img src={us_flag} alt='us flag thumbnail' style={{width: '30px', height: '20px', marginBottom: '5px'}}/> :
               <img src={pol_flag} alt='polish flag thumbnail'  style={{width: '30px', height: '20px', marginBottom: '5px'}}/>
             }
              <div style={{fontSize: 'small', color: 'white'}}
                  onClick={() => this.props.changeLanguage(this.findArrayElementById(this.props.active_lang.other_lang, this.props.languages))}
                  >  {this.props.active_lang.other_lang.toUpperCase() }
              </div>
          </div>
      );
    }
  };

function mapStateToProps(state) {
  console.log('state.activeLanguage', state.activeLanguage);
  return {
      languages: state.languages
    , active_lang: state.activeLanguage
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({changeLanguage: changeLanguage}, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(LangSwitcher);
