import React, { Component }  from "react";
import { Alert } from 'react-bootstrap';
// Redux
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';
import { closeBanner } from '../actions/index.js';

class BookBanner extends Component {

  render() {
      return (
      <div>
        { this.props.closed_banner === true ?
          <Alert variant="dark" onClose={() => this.props.closeBanner(false)} dismissible>
            <p>
              My book THE COMPLETE CRYPTOCURRENCY INVESTOR is out now!
            </p>
          </Alert> :
          <div> </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  console.log('state.closeBanner', state.closeBanner);
  return {
      languages: state.languages
    , active_lang: state.activeLanguage
    , closed_banner: state.closeBanner
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({closeBanner: closeBanner}, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(BookBanner);
