import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
// Multi Langauage Support
import { I1BnProvider } from '../../i18n';
import translate from '../../i18n/translate.js';
//images
import BookCover from '../../images/complete_cover_cropped.jpg';
import BitcoinBlogPic from '../../images/bitcoin_blog_cropped.jpg';
import MeadBlogPic from '../../images/wine_sunset_cropped.jpg';

class TopicNavTriplet extends Component {
  render(){
    return (
      <div>
        <div style={{textAlign: 'center', marginTop: '3%'}}>
        <h3 style={{float: 'left', height: '100%', width: '33%', paddingLeft:'3%'}}>  { translate('books') }  </h3>
        <h3 style={{float: 'left', height: '100%', width: '33%', paddingLeft:'3%'}}> { translate('navBar_crypto') } Blog </h3>
        <h3 style={{float: 'left', height: '100%', width: '34%'}}> { translate('navBar_mead') } </h3>
        </div>
        <div id='triple-image-container' style={{width: '100%', minWidth: '800px', maxHeight: '500px'}}>
        <div style={{float: 'left', width: '8%', backgroundColor: 'white'}}>
        </div>
        <div style={{textAlign: 'center'}}>
          <Link className="NavbarLink" to="/cryptocurrency">
              <img src={BookCover}
                   alt="complete cryptocurrency investor book image"
                   style={{float: 'left', height: '100%', width: '33%',  paddingRight:'1%',
                           paddingLeft:'4%', minWidth: '280px', maxHeight: '500px'}}
              />
          </Link>
        </div>
        <div style={{textAlign: 'center'}}>
          <Link className="NavbarLink" to="/cryptocurrency">
              <img src={BitcoinBlogPic}
                   alt="cryptocurrency blog picture"
                  style={{float: 'left', height: '100%', width: '33%',
                          paddingLeft:'3%', paddingRight:'2%',
                          maxHeight: '402px',
                          minWidth: '245px'}}
              />
          </Link>
        </div>
        <div style={{textAlign: 'center'}}>
          <Link className="NavbarLink" to="/mead">
              <img src={MeadBlogPic}
                   alt="mead blog picture"
                   style={{float: 'left', height: '100%', width: '33%',
                           marginTop:'-75px', paddingRight:'2%',
                           paddingLeft:'2%', maxHeight: '452px',
                           minWidth: '280px'}}
              />
          </Link>
        </div>
       </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  console.log('state.activeLanguage', state.activeLanguage);
  return {
      active_lang: state.activeLanguage
  };
}

export default connect(mapStateToProps)(TopicNavTriplet);
