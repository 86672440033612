const default_selection = true

export default function (prior_state=default_selection, action) {
    switch(action.type) {
      case 'CLOSE_BANNER':
        console.log('recieved listener for CLOSE_BANNER');
        return {
                ...prior_state,
                payload : action.payload
              };
    default:
    return prior_state;
   }
};
